import '../scss/app.scss'; // Importing SCSS file

// Send a message to the iframe to perform an action
document.addEventListener('DOMContentLoaded', function() {
    let iframe = [...document.querySelectorAll('iframe')].filter(iframe => iframe.src === 'https://form.jotform.com/form/241566020642045')[0];
    if (iframe) {
        iframe.onload = function() {
            iframe.contentWindow.postMessage({ action: 'modifySelect' }, '*');
        };
    }
});

// Listen for messages from the iframe
window.addEventListener('message', function(event) {
    if (event.origin !== 'https://form.jotform.com') return; // Ensure the message is from the correct origin

    // Handle the response from the iframe if needed
    console.log(event.data);
});


 
document.addEventListener('DOMContentLoaded', () => {
    // let isGrouped = null; // Tracks the current state: grouped (true) or reset (false)
    // const harmReduction = document.getElementById('knoxville-headquarters-medical'); // Get the harm reduction element
    // const prevention = document.getElementById('knoxville-headquarters-harm-reduction-mobile-outreach-sites'); // Get the prevention element
    // let groupedDiv = null; // Placeholder for the dynamically created grouped container
    // if (!harmReduction || !prevention) return; // Return early if necessary elements are missing
    // let prevGroupState = null; // Placeholder for the previous group state

    // const groupKnoxvilleDivs = () => {
    //     if (!groupedDiv) { // If the grouped container doesn't exist, create it
    //         groupedDiv = document.createElement('div'); // Create a new div element
    //         groupedDiv.appendChild(harmReduction.cloneNode(true)); // Clone the harm reduction element and append it to the new div
    //         groupedDiv.appendChild(prevention.cloneNode(true)); // Clone the prevention element and append it to the new div
    //         harmReduction.parentNode.insertBefore(groupedDiv, harmReduction); // Insert the new div before the harm reduction element
    //     }
    //     harmReduction.style.display = 'none'; // Hide the harm reduction element
    //     prevention.style.display = 'none'; // Hide the prevention element
    //     isGrouped = true; // Update the state to grouped
    // };

    // const setDisplay = (groupedDisplay = "block", originalDisplay = "none", isGrouped = true) => { // Set the display of the elements
    //     groupedDiv.style.display = groupedDisplay; // Set the display of the grouped container
    //     harmReduction.style.display = originalDisplay;// Set the display of the harm reduction element
    //     prevention.style.display = originalDisplay; // Set the display of the prevention element
    //     isGrouped = isGrouped; // Update the state
    // };

    // const handleResize = (loading = false) => {

    //     if (loading) {
    //         setDisplay();
    //     }
    //     const groupState = window.innerWidth >= 800 ? "Desktop" : "Mobile"; // Determine the current group state
    //     let stateChange = groupState !== prevGroupState; // Determine if the group state has changed
    //     if (stateChange && groupState === "Desktop") {
    //         setDisplay();
    //     } else if (stateChange && groupState === "Mobile") {
    //         setDisplay("none", "block", false);
    //     }
    //     // groupState === prevGroupState ? setDisplay() : setDisplay("none", "block", false); // Update the display based on the window width
    //     prevGroupState = groupState; // Update the previous group state
     
          
    // };

  
    // window.addEventListener('resize', handleResize);
    // groupKnoxvilleDivs();
    // handleResize(true);


    let isGrouped = null;
    const harmReduction = document.getElementById('knoxville-headquarters-medical');
    const prevention = document.getElementById('knoxville-headquarters-harm-reduction-mobile-outreach-sites');
    let groupedDiv = null;
    if (!harmReduction || !prevention) return;
    
    let prevGroupState = null;
    
    // Save original parent and next sibling of each
    const harmReductionParent = harmReduction.parentNode;
    const harmReductionNext = harmReduction.nextElementSibling;
    const preventionParent = prevention.parentNode;
    const preventionNext = prevention.nextElementSibling;
    
    // Function to group both elements into a new div
    const groupKnoxvilleDivs = () => {
        if (!groupedDiv) {
            groupedDiv = document.createElement('div');
            groupedDiv.classList.add('grouped-knoxville-container'); // Optional class for styling
            groupedDiv.appendChild(harmReduction);
            groupedDiv.appendChild(prevention);
            harmReductionParent.insertBefore(groupedDiv, harmReductionNext);
        }
        groupedDiv.style.display = 'block';
        harmReduction.style.display = 'block';
        prevention.style.display = 'block';
        isGrouped = true;
    };
    
    // Function to restore elements to original DOM positions
    const restoreOriginalPlacement = () => {
        if (groupedDiv) {
            groupedDiv.remove(); // Remove the wrapper
            // Restore original positions
            if (harmReductionNext) {
                harmReductionParent.insertBefore(harmReduction, harmReductionNext);
            } else {
                harmReductionParent.appendChild(harmReduction);
            }
    
            if (preventionNext) {
                preventionParent.insertBefore(prevention, preventionNext);
            } else {
                preventionParent.appendChild(prevention);
            }
    
            groupedDiv = null;
        }
    
        harmReduction.style.display = 'block';
        prevention.style.display = 'block';
        isGrouped = false;
    };
    
    // Handles resizing logic
    const handleResize = (loading = false) => {
        const currentView = window.innerWidth >= 1024 ? 'Desktop' : 'Mobile';
        const stateChanged = currentView !== prevGroupState;
    
        if (stateChanged) {
            if (currentView === 'Desktop') {
                groupKnoxvilleDivs();
            } else {
                restoreOriginalPlacement();
            }
            prevGroupState = currentView;
        }
    };
    
    // Initial logic
    window.addEventListener('resize', handleResize);
    handleResize(true);
 
});




const topMenu = document.querySelector("#et_mobile_nav_menu");
    
 

if (topMenu) {
    console.log("Top menu found, adding donation button.");
        
        // Create the button element
        const donateButton = document.createElement("a");
        donateButton.href = "/donate"; // Change this to the actual donation link
        donateButton.classList.add("donation-button");
        donateButton.innerText = "Donate";
        donateButton.setAttribute("aria-label", "Donate");

        // Append button to the menu
        topMenu.insertAdjacentElement('beforebegin', donateButton);
        
    } else {
        console.error("Error: #top-menu not found.");
    }